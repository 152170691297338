import { useEffect } from "react";

const useDisableBodyScroll = (condition: boolean = true): void => {
  useEffect(() => {
    const currentState = document.body.style.overflow;
    document.body.style.overflow = condition ? "hidden" : currentState;
    return () => {
      document.body.style.overflow = currentState;
    };
  }, [condition]);
};

export default useDisableBodyScroll;
