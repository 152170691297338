import "utils/date";

import React, { FC, useEffect, useRef, useState } from "react";

import Breadcrumbs from "components/molecules/Breadcrumbs";
import ModalImage from "components/organisms/ModalImage";
import DefaultLayout from "components/templates/DefaultLayout";
import dayjs from "dayjs";
import { GatsbyImage } from "gatsby-plugin-image";
import cn from "utils/cn";

import * as styles from "./Post.module.scss";
import IPost from "./Post.type";

const Post: FC<IPost> = ({ data: { wpPost: post } }) => {
  const content = useRef<HTMLDivElement>(null);
  const [modalImageSrc, setModalImageSrc] = useState<string>("");

  useEffect(() => {
    // get the biggest image from image's srcset and set it as modalImageSrc state
    setTimeout(() => {
      // inside a timeout to give time for loading everything, and (almost) no user will ever click on gallery image in the first 3 seconds after entering the page
      if (!content.current) return;
      const images = content.current.querySelectorAll(".wp-block-gallery img");
      if (images.length === 0) return;
      Array.from(images).forEach((image) => {
        image.addEventListener("click", (e) => {
          const img = e.target as HTMLImageElement;
          const sizes = img.srcset.split(",").map((size) => {
            const sizeTuple = size.split(" ").map((el, i) => (i === 1 ? +el.split("w")[0] : el));
            return sizeTuple as [string, number];
          });
          sizes.sort((a, b) => (a[1] > b[1] ? -1 : 1)); // sort from highest resolution to lowest
          setModalImageSrc(sizes[0][0]);
        });
      });
    }, 3000);
  }, []);
  return (
    <DefaultLayout>
      <Breadcrumbs
        items={[
          { label: "Strona główna", href: "/" },
          { label: "Poradniki", href: "/poradniki" },
          { label: post.title },
        ]}
      />
      <article className={cn(styles.element, "container")}>
        <header>
          <div className={styles.top}>
            <h1 className={styles.postTitle}>{post.title}</h1>
            <div className={styles.row}>
              <div className={styles.date}>
                Opublikowano: {dayjs(post.date).format("D MMM YYYY")} | autor: {post.author.node.name}
              </div>
              {dayjs(post.modified).diff(post.date, "day") > 0 && (
                <div className={styles.modifiedDate}>Zaktualizowano: {dayjs(post.modified).format("D MMM YYYY")}</div>
              )}
            </div>
          </div>
          <GatsbyImage
            className={styles.featuredImage}
            image={post.featuredImage?.node.localFile.childImageSharp.gatsbyImageData}
            alt="featured"
          />
        </header>
        <div ref={content} className={styles.postContent} dangerouslySetInnerHTML={{ __html: post.content }} />
      </article>
      <ModalImage src={modalImageSrc} isOpen={!!modalImageSrc} onClose={() => setModalImageSrc("")} />
    </DefaultLayout>
  );
};

export default Post;
