// extracted by mini-css-extract-plugin
export var cBlack = "Post-module--c-black--eM5xz";
export var cBlue = "Post-module--c-blue--49n42";
export var cGray = "Post-module--c-gray--5Xtjf";
export var cNavy = "Post-module--c-navy--TPkdq";
export var cNavyBg = "Post-module--c-navy-bg--+o8Bi";
export var cWhite = "Post-module--c-white--BfEjw";
export var date = "Post-module--date--K6lya";
export var displayLarge = "Post-module--display-large--v3agY";
export var displaySmall = "Post-module--display-small--AizLu";
export var featuredImage = "Post-module--featuredImage--BPyFx";
export var fwBold = "Post-module--fw-bold--bpLIp";
export var h1 = "Post-module--h1--ZsRjS";
export var h2 = "Post-module--h2--VXnqW";
export var h3 = "Post-module--h3--gcmXW";
export var h4 = "Post-module--h4--OcuzX";
export var modifiedDate = "Post-module--modifiedDate--F7sp1";
export var postContent = "Post-module--postContent--iwaaq";
export var postTitle = "Post-module--postTitle--Qn9wM";
export var sLarge = "Post-module--s-large--DcOlp";
export var sMedium = "Post-module--s-medium--Tyid2";
export var sSmall = "Post-module--s-small--q1nRk";
export var top = "Post-module--top--j8pFi";