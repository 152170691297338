import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import closeIcon from "assets/vector/close-icon.svg";
import cn from "utils/cn";

import * as styles from "./ModalImage.module.scss";
import IModalImage from "./ModalImage.type";
import useDisableBodyScroll from "hooks/useDisableBodyScroll";

const ModalImage: FC<IModalImage> = ({ src, className, isOpen, onClose, closeOnBackgroundClick = true }) => {
  useDisableBodyScroll(!!src);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageZoom, setImageZoom] = useState<number>(1);
  const [imageTransformOrigin, setImageTransformOrigin] = useState("");

  useEffect(() => {
    const modalRoot = document.getElementById("modal-root");
    if (!modalRoot)
      throw new Error("No #modal-root found in the document. Add element with id='modal-root' to use ModalImage");

    containerRef.current = document.createElement("div");
    const container = containerRef.current;
    modalRoot.appendChild(container);
    return () => {
      modalRoot.removeChild(container);
    };
  }, []);

  useEffect(() => {
    if (imageZoom === 1 || !src || !imageRef.current) return;

    const onMousemove = (e: MouseEvent) => {
      setImageTransformOrigin(`${e.offsetX}px ${e.offsetY}px`);
    };
    const onTouchmove = (e: TouchEvent) => {
      const boundingClientRect = (e.target as HTMLImageElement).getBoundingClientRect();
      const offsetX = (e.targetTouches[0].clientX - boundingClientRect.left) / imageZoom;
      const offsetY = (e.targetTouches[0].clientY - boundingClientRect.top) / imageZoom;
      setImageTransformOrigin(`${offsetX}px ${offsetY}px`);
    };
    imageRef.current.addEventListener("mousemove", onMousemove);
    imageRef.current.addEventListener("touchmove", onTouchmove);

    return () => {
      imageRef.current?.removeEventListener("mousemove", onMousemove);
      imageRef.current?.removeEventListener("touchmove", onTouchmove);
    };
  }, [src, imageZoom]);

  useEffect(() => {
    if (!src) {
      setImageZoom(1);
      setImageTransformOrigin("");
    }
  }, [src]);

  const onBackgroundClick = useCallback(() => {
    if (closeOnBackgroundClick) onClose();
  }, [onClose, closeOnBackgroundClick]);

  const onZoomSliderChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setImageZoom(parseFloat(e.target.value));
  }, []);

  return isOpen && containerRef.current
    ? createPortal(
        <div className={styles.overlay} onClick={onBackgroundClick}>
          <div className={cn(className, styles.window)} onClick={(e) => e.stopPropagation()}>
            <div className={styles.buttonWrapper}>
              <p className="s-medium">
                Zoom: <span>x{imageZoom}</span>
              </p>
              <input
                type="range"
                min={1}
                max={5}
                step={0.01}
                defaultValue={1}
                className={styles.zoomSlider}
                onChange={onZoomSliderChange}
              />
              <button onClick={onClose} title="Zamknij okno">
                <img src={closeIcon} alt="Zamknij okno" />
              </button>
            </div>
            <img
              src={src}
              alt=""
              ref={imageRef}
              style={{
                transform: `scale(${imageZoom})`,
                transformOrigin: imageTransformOrigin,
                cursor: imageZoom > 1 ? "zoom-in" : "",
              }}
            />
          </div>
        </div>,
        containerRef.current
      )
    : null;
};

export default ModalImage;
