import dayjs from "dayjs";

// load polish language for date functions
import "dayjs/locale/pl";
// load plugins
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

// <Settings>
dayjs.locale("pl");
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("pl", {
  relativeTime: {
    future: "za %s",
    past: "%s temu",
    s: "< 1min",
    m: "1min",
    mm: "%dmin",
    h: "1g",
    hh: "%dg",
    d: "1d",
    dd: "%dd",
    M: "1mies",
    MM: "%dmies",
    y: "1r",
    yy: "%dl",
  },
});
// </Settings>

export const getCountdown = (date: string | number | dayjs.Dayjs) => {
  let diff = dayjs(date).diff(dayjs(), "millisecond");
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -= days * (1000 * 60 * 60 * 24);
  const hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);
  const minutes = Math.floor(diff / (1000 * 60));
  diff -= minutes * (1000 * 60);
  const seconds = Math.floor(diff / 1000);
  return { days, hours, minutes, seconds };
};

// export const isTheSameMinute = (date1, date2) => {
//   return (
//     dayjs(date1).format("YYYY MM DD HH:mm") ===
//     dayjs(date2).format("YYYY MM DD HH:mm")
//   );
// };
// export const isTheSameDay = (date1, date2) => {
//   return (
//     dayjs(date1).format("YYYY MM DD") === dayjs(date2).format("YYYY MM DD")
//   );
// };
// export const timeDifference = (date1, date2, upTo = "milisecond") => {
//   return dayjs(date1).diff(date2, upTo);
// };
// export const isDiffSmallerThanAMinute = (date1, date2) => {
//   return !Math.abs(timeDifference(date1, date2, "minute"));
// };
// export const isDiffSmallerThanADay = (date1, date2) => {
//   return !Math.abs(timeDifference(date1, date2, "day"));
// };
// export const displayDay = date => {
//   return dayjs(date).format("DD.MM.YYYY");
// };
// export const displayDate = date => {
//   return dayjs(date).format("DD.MM.YYYY, HH:mm");
// };
// export const displayHourAndMinute = date => {
//   return dayjs(date).format("HH:mm");
// };
// export const displayTimeFromNow = (date, isPrefixOrSuffixHidden = false) => {
//   return dayjs(date).fromNow(isPrefixOrSuffixHidden);
// };
// export const displayDateWithCustomFormat = (date, format) => {
//   return dayjs(date).format(format);
// };

// <FormatsCheatSheet>
// YY	                  18	Two-digit year
// YYYY	              2018	Four-digit year
// M	                1-12	The month, beginning at 1
// MM	               01-12	The month, 2-digits
// MMM	           Jan-Dec	The abbreviated month name
// MMMM	  January-December	The full month name
// D	                1-31	The day of the month
// DD	               01-31	The day of the month, 2-digits
// d	                 0-6	The day of the week, with Sunday as 0
// dd	               Su-Sa	The min name of the day of the week
// ddd	           Sun-Sat	The short name of the day of the week
// dddd	   Sunday-Saturday	The name of the day of the week
// H	                0-23	The hour
// HH	               00-23	The hour, 2-digits
// h	                1-12	The hour, 12-hour clock
// hh	               01-12	The hour, 12-hour clock, 2-digits
// m	                0-59	The minute
// mm	               00-59	The minute, 2-digits
// s	                0-59	The second
// ss	               00-59	The second, 2-digits
// SSS	           000-999	The millisecond, 3-digits
// Z	              +05:00	The offset from UTC, ±HH:mm
// ZZ	               +0500	The offset from UTC, ±HHmm
// A	               AM PM
// a	               am pm
// </FormatsCheatSheet>

// <UpdatedSettings>
// const polishCountables = (firstForm, secondForm) => (x) => {
//   let returnString = `${x} `;
//   const lastDigit = x % 10;
//   const lastTwoDigits = x % 100;

//   if (
//     (lastTwoDigits > 10 && lastTwoDigits < 20) ||
//     lastDigit <= 1 ||
//     lastDigit >= 5
//   ) {
//     returnString += firstForm;
//   } else {
//     returnString += secondForm;
//   }

//   return returnString;
// };

// dayjs.updateLocale("pl", {
//   relativeTime: {
//     future: "za %s",
//     past: "%s temu",
//     s: "kilka sekund",
//     m: "%dmin",
//     mm: polishCountables("minut", "minuty"),
//     h: "%dg",
//     hh: polishCountables("godzin", "godziny"),
//     d: "%dd",
//     dd: "%d dni",
//     M: "miesiąc",
//     MM: polishCountables("miesięcy", "miesiące"),
//     y: "%drok",
//     yy: polishCountables("lat", "lata"),
//   },
// });

// </UpdatedSettings>
