import React from "react";

import Post from "components/pages/Post";
import { graphql } from "gatsby";
import { IWpPostData } from "components/pages/Post/Post.type";

export default function ({ data }: { data: IWpPostData }) {
  return <Post data={data} />;
}

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      excerpt
      date
      desiredSlug
      content
      modified
      slug
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
      author {
        node {
          name
        }
      }
    }
  }
`;
